import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Header from '../../components/Header';
import Button from '../../components/Button';
import LineChart from './LineChart';
import Temperature from './Temperature';
import ProgressCard from './ProgressCard';
import {WaterQuantity} from '../../assets';
import CustomBarChart from './BarChart';
import StackedBarChart from './StackedBarChart';
import TinyLineChart from './TinyLineChart';
import CustomCalendar from '../../components/CustomCalendar';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from 'react-query';
import {fetchUserStats, fetchUsers} from '../../services/clients';
import {api} from '../../services/axiosInstance';
import {routes} from '../../constants/routes';
import Loader from '../../components/Loader';
import {DayRange as CalendarDayRange} from '@hassanmojab/react-modern-calendar-datepicker';
import moment from 'moment';
import {AxiosError} from 'axios';

// Images
import activityImage from '../../assets/activity.png';
import caloriesImage from '../../assets/calories.png';
import caloriesBurnedImage from '../../assets/caloriesBurned.png';
import carbsImage from '../../assets/carbs.png';
import fatImage from '../../assets/fat.png';
import proteinImage from '../../assets/protein.png';
import scaleImage from '../../assets/scale.png';
import stepsImage from '../../assets/steps.png';
import waterImage from '../../assets/water.png';
import {useLocation} from 'react-router-dom';

type Props = {};

const handlePushNotification = ({message, recipientId}: any) => {
  const response = api.post(`${routes.SEND_PUSH_NOTIFICATION}`, {
    recipientId,
    message,
  });
  return response;
};

const WaterGraph = ({
  waterIntakePercentage,
}: {
  waterIntakePercentage: number;
}) => {
  let imageHeight =
    0 +
    ((waterIntakePercentage > 100 ? 100 : waterIntakePercentage) * (130 - 0)) /
      100;
  return (
    <div className="oval mt-2">
      <img
        src={WaterQuantity}
        alt="water-quantity"
        className={`w-full object-contain h-[${imageHeight}px]`}
      />
    </div>
  );
};

function Index({}: Props) {
  const {t} = useTranslation();

  const location = useLocation();
  const userId = location.state?.userId;

  const [pushMessage, setPushMessage] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<any>({
    id: null,
    fname: 'select',
    lname: 'user',
  });
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [nutritionList, setNutritionList] = useState([
    {
      id: 1,
      label: t('calories'),
      key: 'calories',
      quantity: 0,
      icon: caloriesImage,
    },
    {
      id: 2,
      label: t('fat'),
      key: 'fats',
      quantity: 0,
      icon: fatImage,
    },
    {
      id: 3,
      label: t('protein'),
      key: 'protein',
      quantity: 0,
      icon: proteinImage,
    },
    {
      id: 4,
      label: t('Carbs'),
      key: 'carbs',
      quantity: 0,
      icon: carbsImage,
    },
  ]);

  // Calculate the "from" date as 7 days before today
  // const fromDate = moment().subtract(7, 'days');
  const fromDate = moment();
  // Get the current date as "to" date
  const toDate = moment();
  const defaultValue: CalendarDayRange = {
    from: {
      // year: fromDate.year(),
      // month: fromDate.month() + 1, // moment months are 0-indexed, so add 1
      // day: fromDate.date(),
      year: fromDate.year(),
      month: fromDate.month() + 1, // moment months are 0-indexed, so add 1
      day: fromDate.date(),
    },
    to: {
      year: toDate.year(),
      month: toDate.month() + 1, // moment months are 0-indexed, so add 1
      day: toDate.date(),
    },
  };
  const [selectedDayRange, setSelectedDayRange] =
    useState<CalendarDayRange>(defaultValue);

  const {
    data: usersData,
    error,
    isLoading,
  } = useQuery(['users'], () => fetchUsers(1, 200, ''), {
    keepPreviousData: true,
  });

  const {mutate: sendNotification} = useMutation(handlePushNotification, {
    onSuccess: () => {
      setPushMessage('');
      alert('success');
    },
    onError: (error: AxiosError<{message: string}>) => {
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        'An unexpected error occurred.';
      alert(errorMessage);
    },
  });

  // Check if from/to dates are valid
  const isValidDateRange = useMemo(() => {
    return selectedDayRange?.from !== null && selectedDayRange?.to !== null;
  }, [selectedDayRange]);

  // Wrap the fetchUserStats call to use selectedUser and selectedDayRange
  const fetchUserStatsWrapper = () => {
    if (selectedUser?.id && isValidDateRange) {
      // Convert the dates to ISO format (2025-01-01T00:00:00.000Z)
      const fromFormatted = moment(
        `${selectedDayRange?.from?.year}-${selectedDayRange?.from?.month}-${selectedDayRange?.from?.day}`,
      )
        .startOf('day')
        .toISOString(); // 'startOf('day')' to make sure time is 00:00:00.000

      const toFormatted = moment(
        `${selectedDayRange?.to?.year}-${selectedDayRange?.to?.month}-${selectedDayRange?.to?.day}`,
      )
        .endOf('day')
        .toISOString(); // 'endOf('day')' to make sure time is 23:59:59.999

      // Convert ISO strings back to moment objects
      const fromDate = moment(fromFormatted);
      const toDate = moment(toFormatted);

      // Calculate the difference in days
      const daysDifference = toDate.diff(fromDate, 'days');
      setNumberOfDays(daysDifference + 1);

      return fetchUserStats(selectedUser?.id, fromFormatted, toFormatted);
    }
    return Promise.resolve(null); // Return a resolved promise if invalid
  };

  // React Query: Call the fetchUserStats API only when userId is valid and dates are valid
  const {
    data: userStatsData,
    error: userStatsError,
    isLoading: isUserStatsLoading,
  } = useQuery(
    ['userStats', selectedUser?.id, selectedDayRange],
    fetchUserStatsWrapper,
    {
      enabled: selectedUser?.id && isValidDateRange ? true : false, // Only run the query if userId and date range are valid
      keepPreviousData: true, // Keep previous data while loading new data
    },
  );

  const totalWaterIntake = useMemo(() => {
    if (userStatsData?.water?.entries?.length) {
      return Math.round(
        userStatsData?.water?.entries?.reduce(
          (acc: any, entry: any) => acc + entry?.lifestyle_value,
          0,
        ) / numberOfDays,
      );
    } else {
      return 0;
    }
  }, [userStatsData, numberOfDays]);

  const goalWaterIntake = useMemo(() => {
    return Math.round((numberOfDays * 8) / numberOfDays);
  }, [userStatsData, numberOfDays]);

  const waterIntakePercentage = useMemo(() => {
    return Math.round((totalWaterIntake / goalWaterIntake) * 100);
  }, [userStatsData, totalWaterIntake, goalWaterIntake]);

  const totalStepsTaken = useMemo(() => {
    if (userStatsData?.steps?.entries?.length) {
      return Math.round(
        userStatsData?.steps?.entries?.reduce(
          (acc: any, entry: any) => acc + entry?.lifestyle_value,
          0,
        ) / numberOfDays,
      );
    } else {
      return 0;
    }
  }, [userStatsData, numberOfDays]);

  const totalCaloriesBurned = useMemo(() => {
    if (userStatsData?.steps?.entries?.length) {
      return Math.round(
        userStatsData?.steps?.entries?.reduce(
          (acc: any, entry: any) => acc + entry?.calories_burned,
          0,
        ) / numberOfDays,
      );
    } else {
      return 0;
    }
  }, [userStatsData, numberOfDays]);

  const totalActivityTime = useMemo(() => {
    if (userStatsData?.workout?.entries?.length) {
      return Math.round(
        userStatsData?.workout?.entries?.reduce(
          (acc: any, entry: any) => acc + entry?.lifestyle_value,
          0,
        ) / numberOfDays,
      );
    } else {
      return 0;
    }
  }, [userStatsData, numberOfDays]);

  const totalActivityCaloriesBurned = useMemo(() => {
    if (userStatsData?.workout?.entries?.length) {
      return Math.round(
        userStatsData?.workout?.entries?.reduce(
          (acc: any, entry: any) => acc + entry?.calories_burned,
          0,
        ) / numberOfDays,
      );
    } else {
      return 0;
    }
  }, [userStatsData, numberOfDays]);

  useEffect(() => {
    if (usersData && usersData.data.length > 0) {
      if (userId) {
        let user = usersData.data.find((user: any) => user.id == userId);
        setSelectedUser(user);
      } else {
        setSelectedUser(usersData.data[0]);
      }
    }
  }, [usersData, userId]);

  useEffect(() => {
    let prevNutritionList = [...nutritionList];
    let newNutritionList = prevNutritionList.map((item: any) => {
      if (item.id == 1) {
        const totalCaloriesGained =
          userStatsData?.food?.total_calories_gained || 0;

        return {
          ...item,
          label: t('calories'),
          quantity: Math.round(totalCaloriesGained / numberOfDays),
        };
      } else if (item.id == 2) {
        const totalFatGained = userStatsData?.food?.total_fat_gained || 0;
        return {
          ...item,
          label: t('fat'),
          quantity: Math.round(totalFatGained / numberOfDays),
        };
      } else if (item.id == 3) {
        const totalProteinGained =
          userStatsData?.food?.total_protein_gained || 0;
        return {
          ...item,
          label: t('protein'),
          quantity: Math.round(totalProteinGained / numberOfDays),
        };
      } else {
        const totalCarbsGained = userStatsData?.food?.total_carbs_gained || 0;
        return {
          ...item,
          label: t('Carbs'),
          quantity: Math.round(totalCarbsGained / numberOfDays),
        };
      }
    });
    setNutritionList(newNutritionList);
  }, [t, userStatsData, numberOfDays]);

  const handleUserChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: any | null) => {
      setSelectedUser(newValue ? newValue : null);
    },
    [setSelectedUser],
  );

  const sendPushNotification = async () => {
    if (selectedUser && pushMessage) {
      await sendNotification({
        message: pushMessage,
        recipientId: selectedUser?.id,
      });
    } else {
      return alert('Please select user and enter message');
    }
  };

  const WeightWidget = ({weight}: {weight: any}) => {
    const {t} = useTranslation();

    const lastWeightEntry =
      weight && weight?.entries?.length
        ? weight?.entries[weight?.entries?.length - 1]
        : null;
    const lastWeightValue = lastWeightEntry?.lifestyle_value || 0;
    const lastDate = lastWeightEntry?.datetime
      ? moment(lastWeightEntry?.datetime).format('MMMM DD, YYYY')
      : null;

    return (
      <div className="flex items-center gap-2 justify-center rounded-[12px]">
        <img src={scaleImage} alt="weight" className="size-[60px]" />
        <div className="flex flex-col">
          <p className="text-[20px] font-medium">{t('lastweight')}</p>
          <p className="text-[20px] font-medium">
            <span className="text-[41px] font-medium">{lastWeightValue}</span>{' '}
            {t('kg')}
          </p>
          <p className="text-semi-dark text-[10px] font-medium">{lastDate}</p>
        </div>
      </div>
    );
  };

  if (error || userStatsError) {
    return <div>Err while fetching user data</div>;
  }

  if (isLoading || isUserStatsLoading) {
    return <Loader />;
  }

  return (
    <div className="bg-white min-h-[100vh] p-8">
      <Header
        title={`${t('dashboardtitle')}, ${localStorage.getItem('userName')}`}
        subtitle={t('statistics')}
        isDashboard
        users={usersData?.data ?? []}
        handleUserChange={handleUserChange}
        selectedUser={selectedUser}
      />
      <div className="h-[400px] w-full mt-8 grid grid-cols-12 gap-4">
        <div
          className={`col-span-3 ${
            nutritionList[0].quantity > selectedUser?.user_daily_goal_nutrition
              ? 'bg-red-100'
              : 'bg-light-green'
          } h-full rounded-[8px] flex flex-col p-4`}>
          <div className="flex flex-col justify-between gap-8">
            <p className="text-[20px] font-medium">{t('nutrition')}</p>
            <div className="flex-1">
              <div className="flex flex-row flex-wrap items-center justify-center gap-3">
                {nutritionList.slice(0, 2).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex-1 flex flex-col p-2 rounded-[12px] bg-white items-end">
                      <img
                        src={item.icon}
                        alt="Calories"
                        className="size-[60px] object-contain"
                      />
                      <div className="flex flex-col w-full">
                        <p className="text-[20px] font-medium">
                          {item.quantity}
                        </p>
                        <p className="text-[16px] text-gray-text">
                          {item.label}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-row flex-wrap items-center justify-center gap-3 mt-3">
                {nutritionList.slice(2, 4).map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex-1 flex flex-col p-2 rounded-[12px] bg-white items-end">
                      <img
                        src={item.icon}
                        alt="Calories"
                        className="size-[60px] object-contain"
                      />
                      <div className="flex flex-col w-full">
                        <p className="text-[20px] font-medium">
                          {item.quantity}
                        </p>
                        <p className="text-[16px] text-gray-text">
                          {item.label}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-6 bg-light-green h-full rounded-[8px] p-4">
          <div className={`flex items-center justify-between`}>
            <div className="flex flex-col gap-7">
              <p className="text-[20px] font-medium mb-2">
                {t('weightprogress')}
              </p>

              <div className="flex items-center gap-5">
                <div className="flex items-center gap-2">
                  <span className="w-3 h-3 bg-[#000] rounded-full" />
                  <p className="text-sm text-[#000]">{t('progress')}</p>
                </div>

                <div className="flex items-center gap-2">
                  <span className="w-3 h-3 bg-[#7ECD93] rounded-full" />
                  <p className="text-sm text-[#7ECD93]">
                    {t('goal')}
                    {selectedUser?.target_weight &&
                      ` (${selectedUser.target_weight} ${t('kg')})`}
                  </p>
                </div>
              </div>
            </div>

            <WeightWidget weight={userStatsData?.weight || null} />
          </div>

          <LineChart
            selectedUser={selectedUser}
            weightEntries={userStatsData?.weight?.entries || []}
          />
        </div>

        <div className="col-span-3 bg-light-green h-full rounded-[8px] pt-4 flex flex-col gap-2">
          <Temperature />
          <CustomCalendar
            selectedDayRange={selectedDayRange}
            setSelectedDayRange={setSelectedDayRange}
          />
        </div>
      </div>
      <div className="h-[350px] w-full mt-4 grid grid-cols-12 gap-4">
        <div className="col-span-3 bg-[#6C91BD1A] bg-opacity-[10%] h-full rounded-[8px] py-4 px-5">
          <ProgressCard
            icon={waterImage}
            title={t('waterintake')}
            score={`${totalWaterIntake}/${goalWaterIntake}`}
            subtitle={t('glasses')}
            graph={<WaterGraph waterIntakePercentage={waterIntakePercentage} />}
          />
        </div>
        <div className="col-span-3 bg-[#FF7C001A] bg-opacity-[10%] h-full rounded-[8px] py-4 px-5">
          <ProgressCard
            icon={stepsImage}
            title={t('stepstaken')}
            score={totalStepsTaken}
            subtitle={t('steps')}
            graph={
              <TinyLineChart
                stepsEntries={userStatsData?.steps?.entries || []}
              />
            }
          />
        </div>
        <div className="col-span-3 bg-[#5046C01A] bg-opacity-[10%] h-full rounded-[8px] py-4 px-5">
          <ProgressCard
            icon={caloriesBurnedImage}
            title={t('caloriesburned')}
            score={totalCaloriesBurned}
            subtitle={t('Cal')}
            graph={
              <CustomBarChart
                caloriesEntries={userStatsData?.steps?.entries || []}
              />
            }
          />
        </div>
        <div className="col-span-3 bg-[#85AF3D1A] bg-opacity-[10%] h-full rounded-[8px] py-4 px-5">
          <ProgressCard
            isActivity
            icon={activityImage}
            workoutName={
              userStatsData?.workout?.entries[
                userStatsData?.workout?.entries?.length - 1
              ]?.lifestyle_name || ''
            }
            title={t('activity')}
            score={totalActivityTime}
            subtitle={t('minute')}
            secondTitle={t('calories')}
            secondScore={totalActivityCaloriesBurned}
            graph={
              <StackedBarChart
                workoutEntries={userStatsData?.workout?.entries || []}
              />
            }
          />
        </div>
      </div>
      <div className="bg-light-green mt-4 rounded-[8px] py-4 px-4 flex flex-col gap-6">
        <p className="font-medium text-[20px]">{t('sendapushmessge')}</p>
        <input
          className="w-full h-[46px] rounded-[8px]  shadow-md border-border-color px-4 focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
          value={pushMessage}
          onChange={e => setPushMessage(e.target.value)}
        />
        <Button
          onClick={sendPushNotification}
          className="w-fit m-auto"
          label={t('send')}
        />
      </div>
    </div>
  );
}

export default Index;
