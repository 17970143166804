import React, {useState, useEffect} from 'react';
import Button from '../../components/Button';
import Logo from '../../components/Logo';
import ScaleImageIcon from '../../assets/scaleImage.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {MuiOtpInput} from 'mui-one-time-password-input';
import {useTranslation} from 'react-i18next';
import {routes} from '../../constants/routes';
import {api} from '../../services/axiosInstance';
import {CircularProgress} from '@mui/material';

type Props = {
  setIsAuthenticated: (val: boolean) => void;
};

function Index({setIsAuthenticated}: Props) {
  const {t} = useTranslation();
  const [phone, setPhone] = useState({phone_number: ''});
  const [currentStatus, setCurrentStatus] = useState<
    'sendOtp' | 'verifyOtp' | 'setUserName'
  >('sendOtp');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isTimerActive) {
      interval = setInterval(() => {
        setResendTimer(prev => {
          if (prev <= 1) {
            setIsTimerActive(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isTimerActive]);

  const handleChange = (value: string) => {
    setPhone({phone_number: '+' + value});
    // setPhone({phone_number: value});
  };

  const handleOtpChange = (newValue: string) => {
    setOtp(newValue);
  };

  const handleFormDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let {name, value} = e.target;
    if (!/\d+/.test(value)) {
      setFormData(prev => ({...prev, [name]: value}));
    }
  };

  const handlePhoneSubmit = async () => {
    if (phone.phone_number.length < 7) {
      return alert('Please enter a valid phone number');
    }
    setLoading(true);
    try {
      const response = await api.post(routes.SEND_OTP, phone);
      if (response.status === 200 && response.data.otp) {
        setCurrentStatus('verifyOtp');
        setIsTimerActive(true);
        setResendTimer(60);
        setOtp(response.data.otp);
      }
    } catch (err) {
      alert('Error sending OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleOtpSubmit = async () => {
    setLoading(true);
    try {
      const response = await api.post(routes.LOGIN, {
        phone_number: phone.phone_number,
        otp,
      });
      if (
        response.status === 200 &&
        response.data.token &&
        response.data.user
      ) {
        localStorage.setItem('userToken', response.data.token);
        if (response.data.user.user_type === 1) {
          localStorage.setItem('isSuperAdmin', JSON.stringify(true));
        }
        localStorage.setItem('user_type', response.data.user?.user_type);

        let firstName = response.data.user?.fname;
        let lastName = response.data.user?.lname;
        if (firstName && lastName) {
          localStorage.setItem('userName', `${firstName} ${lastName}`);
          localStorage.setItem('loggedIn', 'yes');
          setIsAuthenticated(true);
        } else {
          setUserData(response.data.user);
          setCurrentStatus('setUserName');
        }
      }
    } catch (err) {
      alert('Error verifying OTP');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUserName = async () => {
    if (!formData.fname || !formData.lname) {
      alert('Please enter first name and last name');
    }
    setLoading(true);
    try {
      const response = await api.put(routes.UPDATE_USER(userData?.id), {
        user: {
          fname: formData.fname,
          lname: formData.lname,
        },
      });

      if (response.status === 200) {
        setUserData(null);

        localStorage.setItem(
          'userName',
          `${response.data?.fname} ${response.data?.lname}`,
        );
        localStorage.setItem('loggedIn', 'yes');
        setIsAuthenticated(true);
      }
    } catch (err) {
      alert('Error Updating Name');
    } finally {
      setLoading(false);
    }
  };

  const handleResend = () => {
    if (!isTimerActive) {
      handlePhoneSubmit();
    }
  };

  return (
    <div className="h-screen w-full bg-custom-gradient">
      <Logo />
      <div className="flex flex-col items-center justify-center h-auto space-y-4 ">
        <h1 className="text-2xl text-heading-color tracking-wide leading-loose font-medium pb-2">
          {t('signupscaleapp')}
        </h1>

        <div className="flex flex-col items-center justify-center space-y-36">
          {currentStatus === 'sendOtp' && (
            <div dir="ltr">
              <PhoneInput
                enableLongNumbers
                country="il"
                value={phone.phone_number}
                onChange={handleChange}
              />
            </div>
          )}

          {currentStatus === 'verifyOtp' && (
            <div className="flex flex-col items-center gap-6">
              <MuiOtpInput
                value={otp}
                onChange={handleOtpChange}
                className="unique-otp-input-class"
              />
              <p className="font-[400] text-sm text-gray-text">
                {t('notrecieved')}
                {isTimerActive ? (
                  <span className="text-custom-green">
                    {' '}
                    {t('resend')} {`00:${String(resendTimer).padStart(2, '0')}`}
                  </span>
                ) : (
                  <span
                    className="text-custom-green cursor-pointer"
                    onClick={handleResend}>
                    {t('resend')}
                  </span>
                )}
              </p>
            </div>
          )}

          {currentStatus === 'setUserName' && (
            <div className="w-full flex flex-col items-center gap-6">
              <div className="w-full">
                <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                  {t('firstname')}
                </p>
                <input
                  className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                  name="fname"
                  value={formData.fname}
                  onChange={handleFormDataChange}
                />
              </div>

              <div className="w-full">
                <p className="text-xs font-[400] text-dark-text leading-4 mb-1">
                  {t('lastname')}
                </p>
                <input
                  name="lname"
                  value={formData.lname}
                  onChange={handleFormDataChange}
                  className="w-full h-[40px] rounded-[40px] border-[1px] border-border-color px-4 text-xs focus:outline-none focus:ring-1 focus:ring-[#7ECD93] focus:border-[#7ECD93]"
                />
              </div>
            </div>
          )}

          <Button
            onClick={
              currentStatus === 'sendOtp'
                ? handlePhoneSubmit
                : currentStatus === 'verifyOtp'
                ? handleOtpSubmit
                : handleUpdateUserName
            }
            label={
              loading ? (
                <CircularProgress size={25} sx={{color: 'white'}} />
              ) : currentStatus === 'sendOtp' ? (
                t('login')
              ) : currentStatus === 'verifyOtp' ? (
                t('login')
              ) : (
                t('save')
              )
            }
            className="text-sm"
          />
        </div>

        <div className="pl-12 py-4">
          <img
            src={ScaleImageIcon}
            alt="Scale Image"
            className="mt-2 mx-auto"
          />
        </div>

        <div className="flex items-center gap-2">
          <a
            href="https://scale-app.com/he/privacy#content"
            target="_blank"
            className="text-[20px] font-medium text-dark-text hover:underline transition-all">
            {t('privacypolicy')}
          </a>
          <div className="w-[2px] h-[18px] bg-[#000]" />
          <a
            href="https://scale-app.com/he/terms#content"
            target="_blank"
            className="text-[20px] font-medium text-dark-text hover:underline transition-all">
            {t('regulations')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Index;
